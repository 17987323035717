/* general */
.unclickable {
    pointer-events: none;
}

.shadowed {
    filter: drop-shadow(0px 0px 4px rgb(99, 98, 98));
}

.g-c-text {
    background: var(--gradient-color);
    background-repeat: no-repeat;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.df {
    display: flex;
}

.align-fs {
    align-items: flex-start;
}

.w100 {
    width: 100%;
}

.h100 {
    height: 100%;
}