@font-face {
  font-family: Yeezy;
  src: url("/public/fonts/yeezy_tstar-bold-webfont.ttf");
}
@font-face {
  font-family: OpenSans;
  src: url("/public/fonts/OpenSans-VariableFont_wdth\,wght.ttf");
}
* {
  box-sizing: border-box;
}
body {  
  color: #e6e4f7;
  margin: 0;  
  background-color: #0a090c;
  overflow: hidden;
  font-family: "Yeezy";
}

header {
  position: absolute;
  width: 90%;
  justify-content: flex-end;
  padding: 5px;
  height: 60px;
  z-index: 2;
  gap: 10px;
  align-items: center;
}

p, h3 {
  font-family: OpenSans;
  letter-spacing: 1px;
  margin-top: 0;
  margin-bottom: 1rem;
}
li {
  list-style: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
h1 {
  font-family: "Yeezy";
  text-align: center;
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  font-size: 9em;
}
h2 {
  font-size: calc(1.325rem + 0.9vw);
}
h3 {
  font-size: calc(1.3rem + 0.6vw);
}
ul {
  margin-top: 0;
  margin-bottom: 1rem;
  padding-left: 2rem;
}
/* main content*/
main {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: clip;
  overflow-y: scroll;
}
footer {
  position: absolute;
  bottom: 0;
  z-index: 1;
  justify-content: center;
  gap: 10px;
}
.p-btn {
  border: none;
  border-radius: 10px;
  background: var(--gradient-color);
  background-clip: text;
  color: transparent;
  padding: 10px;
  font-size: 1.2em;
  box-shadow: 0 0 5px wheat;
  transition: 250ms ease-in-out;
}

button:not([disabled]):hover {
  cursor: pointer;
}

.p-btn:hover {
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.54);
}
.load-wrapper {
  position: absolute;
  z-index: 3;
  height: 100%;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: 500ms ease-in-out;
  background-color: rgba(0, 0, 0, 0.5);
  visibility: visible;
}

.load-wrapper.off {
  visibility: hidden;
  z-index: -100;
  opacity: 0;
}
/* CUSTOM SCROLLBAR */
::-webkit-scrollbar {
  width: 2px;
  background-color: transparent;
}


::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: var(--gradient-color);
}
@keyframes colorr {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 0 100%;
  }

  100% {
    background-position: 0 0;
  }
}

@keyframes anim {
  0% {
    filter: blur(0px);
    color: white;
  }

  100% {
    filter: blur(10px);
    color: transparent;
  }
}

@keyframes zoomin {
  from {
    scale: 1;
  }

  to {
    scale: 2.5;
    filter: opacity(0);
  }
}

@keyframes scalex {
  to {
    width: 400px;
  }
}

@keyframes opaque {
  to {
    opacity: 1;
    
  }
}

@keyframes appear {
  to {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    opacity: 1;
  }
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-420px);
  }
}