@font-face {
  font-family: Yeezy;
  src: url("/public/fonts/yeezy_tstar-bold-webfont.ttf");
}

/* langauge change link */
.link {
  cursor: pointer;
  font-size: 1em;
  letter-spacing: 5px;
  color: gray;
  text-decoration: none;
  border: none;
  background-color: transparent;
}

.link:hover {
  background: var(--gradient-color);
  background-clip: text;
  color: transparent;
}

.link.active {
  background: var(--gradient-color);
  background-clip: text;
  color: transparent;
}
:root {
  --auto-scroll-max-width: 400px;
  --gradient-color: linear-gradient(to top,
      rgb(245, 173, 125) 0%,
      rgb(255, 246, 231) 50%,
      rgb(245, 173, 125) 80%);
}



main>div {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  z-index: 0;
  box-shadow: none;
  position: relative;
  opacity: 0;
  
  
  transform-origin: center;

  animation: opaque 250ms linear forwards;
  animation-iteration-count: 1;
}
/* About me */
main>div:first-child{
  align-items: flex-start;
}
main>div:last-child{

  animation: unset;
}
#name {
  animation: colorr 5s linear infinite;
  pointer-events: none;
  background-size: 200% 200%;
  letter-spacing: 5px;
  filter: drop-shadow(9px 10px 4px black);
}

#degree {
  position: relative;
}

#degree #degree-img {
  position: absolute;
  width: 500px;
  height: 350px;
  top: 0;
  transition: 500ms ease-in-out;
  transform: translateX(100%);
  scale: 0;
  object-fit: cover;
  filter: drop-shadow(1px 1px 10px black);
}

.pfp {
  animation: zoomin linear forwards;
  -webkit-animation: zoomin linear forwards;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: -1;

}

.pfp img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  /* filter: drop-shadow(19px 10px 100px rgb(14, 14, 14)); */
  pointer-events: none;
}

.social-links {
  gap: 10px;
  align-items: center;
  padding: 15px;
  box-sizing: border-box;
  box-shadow: 0 0 15px 1px rgb(255, 189, 145);
  border-radius: 10px;
}

.social-links li:hover {
  transition: 350ms ease-in-out;
  filter: drop-shadow(0 0 10px rgb(255, 189, 145));

}

.social-links img {
  width: 30px;
}

.console {
  background: #9f9f9f;
  border-radius: 0 5px 5px 0;
  color: #0a090c;
  max-width: 95%;
  height: 200px;
}

.console p {
  background-color: black;
  padding: 15px 5px;
  font-family: monospace;
  font-size: 1.2em;
  color: #2bff2b;
  height: 100%;
  animation: scalex 500ms forwards;
  max-width: 100%;
}

/* Skills*/
.skill-wrapper {
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.skill-item-list {
  gap: 10px;
  justify-content: center;
  max-width: var(--auto-scroll-max-width);
  overflow: hidden;
  box-sizing: content-box;
  padding: 15px;
  border-radius: 10px;
}

.skill-item-list {
  justify-content: flex-start;
  pointer-events: none;
}

.skills-animoji {
  position: absolute;
  width: 300px;
  left: 0;
  transform: translateX(-300px);
  animation: appear 250ms linear forwards;
  animation-timeline: view(70% 20%);
}

.skill-item-list.scroll-anim li {
  box-sizing: content-box;
  animation: scroll linear 5s infinite;
}

.skill-item-list li {
  max-width: 60px;
  min-width: 60px;
  z-index: 1;
}

.skill-icon {
  height: 100%;
  object-fit: cover;
}

/* Experience */
.experience-item img {
  width: 40%;
  max-width: 200px;
  object-fit: cover;
}

.experience-item ul li {
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

/*PROJECTS*/
.active-item {
  position: absolute;
  transition: 0.5s ease-in-out;
  left: 20px;
  flex-direction: column;
  align-items: flex-start;
  top: 50%;
  transform: translateY(-50%);
  width: 50%;
}
.projects {
  gap: 10px;
  pointer-events: none;
  display: flex;
}
.project-view {
  position: relative;
  width: 80%;
  align-items: flex-end;
  box-sizing: content-box;
  gap: 5px;
  pointer-events: none;
  outline: 1px solid rgb(245, 173, 125);
  height: 800px;
  border-radius: 10px;
  justify-content: flex-end;
}
#learn-more-btn {
  pointer-events: all;
  cursor: pointer;
  margin-top: 10px;
  font-size: 1.2em;
}
.project-title {
  font-size: 5em;
  font-weight: bold;
  margin: 0;
  display: inline-block;
}
.project-title span {
  font-size: 0.15em;
  background-color: #0b4e0b;
  padding: 0 5px;
  border-radius: 5px;
  vertical-align: top;
  box-shadow: 0 0 3px 1px #2bff2b;
  font-weight: bold;
  margin: 0;
}

#active-img {
  position: absolute;
  z-index: -1;
  height: 100%;
  object-fit: cover;
  filter: brightness(0.5);
  transition: 0.5s ease-in-out;
}

.project-list {
  max-width: 850px;
  cursor: pointer;
  pointer-events: all;
  border-radius: 10px;
  margin-bottom: 50px;
  box-shadow: 0 0 10px 2px #3b3b3b;
  padding: 10px;
  overflow-x: auto;
  height: 120px;
  gap: 10px;
  place-items: center;
}

.project-list::-webkit-scrollbar {
  width: 2px;
  height: 10px;
}

.project-preview {
  align-items: center;
  flex-direction: row;
  height: 90%;
  position: relative;
  margin: 0;
  width: 150px;
  
  pointer-events: all;
  transition: 150ms ease-in-out;
  border-radius: 10px;
  overflow: clip;
}

.project-preview:hover {
  outline: 2px solid rgb(245, 173, 125);
  transform: scale(1.05);
}

.project-image {
  object-fit: cover;
}

@media (max-width: 768px) {
  main>div {
    width: 100%;
    height: 100%;
  }

  .logo-img {
    max-width: 50px;
  }

  #name {
    font-size: 4em;
  }

  .skills-animoji {
    left: unset;
    width: 100px;
  }

  .pfp {
    animation: unset;
    height: 50%;
    position: absolute;
  }


  .project-preview {
    gap: 0;
  }

  .project-image {
    width: 50%;
    position: relative;
  }

  .project-preview figcaption {
    width: 50%;
    opacity: 1;
    display: flex;
  }

  .project-preview figcaption p {
    font-size: 0.7em;
  }

  .projects {
    height: fit-content;
    display: none;
  }

  /*for projects view animation*/
  .active-item,
  #active-img {
    display: none;
  }

  .project-list {
    align-items: center;
    flex-direction: column;
    gap: 5px;
    max-width: 1000px;
    padding: 5px;
    height: 700px;
    justify-content: flex-start;
    outline: none;
  }
}

